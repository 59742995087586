.limiter{
    width: 100%;
    margin: 0 auto;
   
}

.limiter input{
    border: solid #fff 0px;
}

.container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f2f2f2;  
  }
  .wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
  
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .login100-form {
    width: 100%;
  }

  .login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
  }
  .login100-form-title i {
    font-size: 60px;
  }
  .p-b-26 {
    padding-bottom: 26px;
}

.p-b-48{

    padding-bottom: 48px;
}


.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
  }
  
  .input100 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
    /* display: block; */
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
  }
  

  
  .container-login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
    margin-bottom: 16px;
  }
  
  .wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #a64bf4;
    background: -webkit-linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: -o-linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: -moz-linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    top: 0;
    left: -100%;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    padding: 0 20px;
    width: 100%;
    height: 50px;
  }
  
  .wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
  }

  .ant-input-affix-wrapper {
    border: 0px solid #ffff !important;
}
  