@import '~bootstrap/dist/css/bootstrap.css';
@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url('./assets/fonts/poppins/Poppins-Regular.ttf'); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url('./assets/fonts/poppins/Poppins-Medium.ttf'); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url('./assets/fonts/poppins/Poppins-Bold.ttf'); 
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf'); 
}



body{
    background-color: white;
    font-family: "Nanum Gothic",'Roboto', serif;
  }

  

  .Layout_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #160c0c;
    text-transform: uppercase;
    padding-top: 8px;
    margin-left: 20px;
    }
    
 .Layout_contentWrapper{
    margin-left:50px;
    margin-right:50px;
    margin-bottom:10px;
   
}

.ant-layout {
    background: white !important;
}