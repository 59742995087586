@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.limiter{
    width: 100%;
    margin: 0 auto;
   
}

.limiter input{
    border: solid #fff 0px;
}

.container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f2f2f2;  
  }
  .wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
  
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .login100-form {
    width: 100%;
  }

  .login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
  }
  .login100-form-title i {
    font-size: 60px;
  }
  .p-b-26 {
    padding-bottom: 26px;
}

.p-b-48{

    padding-bottom: 48px;
}


.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
  }
  
  .input100 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
    /* display: block; */
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
  }
  

  
  .container-login100-form-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
    margin-bottom: 16px;
  }
  
  .wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #a64bf4;
    background: linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    top: 0;
    left: -100%;
    transition: all 0.4s;
  }
  
  .login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    padding: 0 20px;
    width: 100%;
    height: 50px;
  }
  
  .wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
  }

  .ant-input-affix-wrapper {
    border: 0px solid #ffff !important;
}
  
/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url(/static/media/Poppins-Regular.1c701c9a.ttf); 
}

@font-face {
  font-family: Poppins-Medium;
  src: url(/static/media/Poppins-Medium.b59f6a63.ttf); 
}

@font-face {
  font-family: Poppins-Bold;
  src: url(/static/media/Poppins-Bold.e2abc8f5.ttf); 
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(/static/media/Poppins-SemiBold.5981b450.ttf); 
}



body{
    background-color: white;
    font-family: "Nanum Gothic",'Roboto', serif;
  }

  

  .Layout_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #160c0c;
    text-transform: uppercase;
    padding-top: 8px;
    margin-left: 20px;
    }
    
 .Layout_contentWrapper{
    margin-left:50px;
    margin-right:50px;
    margin-bottom:10px;
   
}

.ant-layout {
    background: white !important;
}
